@import 'core';
@import 'easing';
@import 'animations';
.resume-button, .nav-button {
  border-color: limegreen;
  color: limegreen;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 150ms ease-in-out;
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: green;
    z-index: -1;
    transition: width 200ms ease-in-out;
  }

  &:hover {
    color: #fff;
    &:after {
      width: 100%;
    }
  }
}
