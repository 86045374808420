:root {
  --primary: #031102;
  --accent: limegreen;
  --selection: #249a24;
  --f-code: 'JetBrains Mono', monospace;
}
::-moz-selection {
  background: var(--selection);
}
::selection {
  background: var(--selection);
}

body {
  /*background-color: var(--primary);*/
  background-color: #061e03;
  color: #fff;
}

#loader {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  color: limegreen;
}

header {
  background-size: cover;
  height: 100vh;
}

.dim {
  color: #b4b4b4;
}

nav {
  -webkit-transition: top 0.3s;
  -o-transition: top 0.3s;
  transition: top 0.3s;
  position: fixed;
  top: 0;
  display: block;
  width: 100%;
  z-index: 2;
  background-color: #061e03;
  border-bottom: 2px solid rgba(255, 255, 255, 1);
}

nav a {
  color: #fff;
  -webkit-transition: ease-in-out 0.3s;
  -o-transition: ease-in-out 0.3s;
  transition: ease-in-out 0.3s;
  text-decoration: none;
}

nav a:hover {
  color: var(--accent);
  text-decoration: none;
}

nav ul {
  min-height: 48px;
  /*width: auto;*/
  text-align: center
}

nav li {
  position: relative;
  list-style: none;
  height: 48px;
  display: inline-block;
  padding: 16px 15px;
  /*letter-spacing: 1px;*/
}

#portrait{
  border-radius: 50%;
  border: 4px var(--accent) solid;
}

header i {
  font-size: 30px;
}

.flex a {
  color: #fff;
  -webkit-transition: ease-in-out .3s;
  -o-transition: ease-in-out .3s;
  transition: ease-in-out .3s;
}

.flex a:hover {
  color: var(--accent);
}

.flex{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  width: 50%;
}

main {
  padding-top: 60px;
  padding-bottom:60px;
  /*background-color: #1b1e21;*/
}

hr {
  background-color: limegreen;
  margin-bottom: 2rem;
}

.img-frame {
  white-space: nowrap;
}

.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.about-portrait{
  vertical-align: middle;
  min-width: 20px;
  -webkit-box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 6px;
}

.proj-container {
  max-width:1000px;
  text-align: left;
}

.proj-button {
  all: unset;
  cursor: pointer;
  position: relative;
}

.proj-button img {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  -webkit-transition: .5s ease;
  -o-transition: .5s ease;
  transition: .5s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  -webkit-transition: .5s ease;
  -o-transition: .5s ease;
  transition: .5s ease;
  background-color: rgba(3, 17, 2, 0.5);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.proj-button:hover .overlay {
  opacity: 1;
}

.card {
  top: 0;
  -webkit-transition: top ease-out .3s;
  -o-transition: top ease-out .3s;
  transition: top ease-out .3s;
  color: white;
  height: 19.5rem;
  -webkit-box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.card:hover {
  top: -10px;
}

.proj-body {
  background-color: #1a1a1a;
  border: none;
}

.proj-icon {
  color: var(--accent);
  font-size: 35px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.code {
  font-family: var(--f-code);
  color: var(--accent);
}
.code-b {
  font-family: "Jetbrains Mono B", monospace;
  color: var(--accent);
}

.code-type {
  font-family: var(--f-code);
}

.hidden {
  opacity: 0;
  -webkit-filter: blur(5px);
  filter: blur(5px);
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 1s;
  -o-transition: all 1s;
  transition: all 1s;
}

.show {
  opacity: 1;
  -webkit-filter: blur(0);
  filter: blur(0);
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

section {
  margin: 10rem auto 3rem auto;
}

.nav-button {
  color: var(--accent);
  border: 1px solid var(--accent);
  padding: 8px 15px;
  border-radius: 5px;
  -webkit-transition: ease-in-out .3ms;
  -o-transition: ease-in-out .3ms;
  transition: ease-in-out .3ms;
}

.link-button {
  color: var(--accent);
  padding: 10px;
  border: 1px solid var(--accent);
  border-radius: 5px;
  -webkit-transition: ease-in-out .3ms;
  -o-transition: ease-in-out .3ms;
  transition: ease-in-out .3ms;
  position: relative;
}

.link-button:hover {
  color: var(--accent);
  text-decoration: none;
}

aside {
  text-align: center;
  text-decoration: none;
  padding: 0 5px 0 5px;
  position: fixed;
  z-index: 3;
  right: 0;
  top: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

aside i {
  top: 0;
}

aside i:hover {
  top: -5px;
}

.mobile-overlay {
  display: none;
  position: absolute;
  z-index: 50;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

@media (max-width: 1250px) {
  aside {
    display: none;
  }
}

/*Apply mobile overlay when width is below 1100*/
@media (max-width: 1100px){
  .mobile-overlay {
    display: block;
    background-color: var(--primary);
  }
  body {
    background-color: var(--primary);
  }
  html, body {margin: 0; height: 100%; overflow: hidden}
}

/*@media (max-width: 1100px){*/
/*  .hide-mobile{*/
/*    display: none;*/
/*  }*/
/*  .center-mobile {*/
/*    margin: 0 auto; !* Horizontally center block elements *!*/
/*    text-align: center; !* Center text and inline elements *!*/
/*    display: flex; !* Enable flexbox for centering *!*/
/*    justify-content: center; !* Horizontal centering for flex items *!*/
/*    align-items: center; !* Vertical centering for flex items *!*/
/*  }*/
/*}*/

aside li {
  list-style: none;
  margin-bottom: 20px;
}

aside ul li a {
  color: var(--accent);
  -webkit-transition: ease-in-out .3s;
  -o-transition: ease-in-out .3s;
  transition: ease-in-out .3s;
}

aside svg {
  height: 30px;
}

#section2 img {
  width: 5vw;
}

.grid-helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.center-mobile {
  margin: 0; /* Reset margin in case it's applied elsewhere */
  text-align: left; /* Default text alignment */
}

.grid img {
  vertical-align: middle;
  max-width: 80px;
  margin:auto;
}

.table-block {
  height: 40%;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  white-space: nowrap;
}

.skills-card {
  /*height: 350px;*/
  background-color: #092509;
  border-radius: 6px;
  top: 50%;
  color: #cecece;
  -webkit-box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  box-shadow: 3px 5px 8px 0 rgba(0, 0, 0, 0.25), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.contact {
  width: 100%;
  padding: 20px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

footer {
  text-align: center;
  /*background-color: #030e02;*/
  background-color: var(--primary);
  padding-bottom: 20px;
}

.type {
  color: limegreen;
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: .08em solid limegreen; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em; /* Adjust as needed */
  -webkit-animation:
          typing 2.3s steps(30, end),
          blink-caret .85s step-end infinite;
  animation:
          typing 2.3s steps(30, end),
          blink-caret .85s step-end infinite;
}

#modal-img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.modal-title {
  color: var(--accent);
}

.modal-body {
  padding: 30px;
  position: relative;
}
.modal-header {
  border-bottom: 2px solid #229122;
}
.modal-footer {
  border-top: 2px solid #229122;
}

/* The typing effect */
@-webkit-keyframes typing {
  from { width: 0 }
  to { width: 100% }
}
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}


/* The typewriter cursor effect */
@-webkit-keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: limegreen; }
}
@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: limegreen; }
}

footer h4 {
  min-font-size: 10px;
  max-font-size: 80px;
  font-size: 130%;
}

p {
  font-family: "Lunasima", serif;
}